import { lazy } from "react";
import { Outlet, useRoutes } from "react-router";

const Intro = lazy(
  () => import("../pages/Intro") /* webpackChunkName: "Intro" */
);

const Tutotials = lazy(
  () => import("../components/tutorials") /* webpackChunkName: "tutorials" */
);

const Screen = lazy(
  () =>
    import("../components/tutorials/Screen") /* webpackChunkName: "Screen" */
);

const NewArtify = lazy(
  () =>
    import(
      "../components/tutorials/NewArtify"
    ) /* webpackChunkName: "NewArtify" */
);

const NewFromFile = lazy(
  () =>
    import(
      "../components/tutorials/NewFromFile"
    ) /* webpackChunkName: "NewFromFile" */
);

const CanvasControl = lazy(
  () =>
    import(
      "../components/tutorials/CanvasControl"
    ) /* webpackChunkName: "CanvasControl" */
);

const AddCrop = lazy(
  () =>
    import("../components/tutorials/AddCrop") /* webpackChunkName: "AddCrop" */
);

const AddDrawing = lazy(
  () =>
    import(
      "../components/tutorials/AddDrawing"
    ) /* webpackChunkName: "AddDrawing" */
);

const AddText = lazy(
  () =>
    import("../components/tutorials/AddText") /* webpackChunkName: "AddText" */
);

const AddImage = lazy(
  () =>
    import(
      "../components/tutorials/AddImage"
    ) /* webpackChunkName: "AddImage" */
);

const UpdateLayer = lazy(
  () =>
    import(
      "../components/tutorials/UpdateLayer"
    ) /* webpackChunkName: "UpdateLayer" */
);

const Eraser = lazy(
  () =>
    import("../components/tutorials/Eraser") /* webpackChunkName: "Eraser" */
);

const ImageFilter = lazy(
  () =>
    import(
      "../components/tutorials/ImageFilter"
    ) /* webpackChunkName: "ImageFilter" */
);

const IntervalSettings = lazy(
  () =>
    import(
      "../components/tutorials/IntervalSettings"
    ) /* webpackChunkName: "IntervalSettings" */
);

const AddFrame = lazy(
  () =>
    import(
      "../components/tutorials/AddFrame"
    ) /* webpackChunkName: "AddFrame" */
);

const RemoveFrame = lazy(
  () =>
    import(
      "../components/tutorials/RemoveFrame"
    ) /* webpackChunkName: "RemoveFrame" */
);

const Preview = lazy(
  () =>
    import("../components/tutorials/Preview") /* webpackChunkName: "Preview" */
);

const SaveGIFToLocal = lazy(
  () =>
    import(
      "../components/tutorials/SaveGIFToLocal"
    ) /* webpackChunkName: "SaveGIFToLocal" */
);

const Shortcut = lazy(
  () =>
    import(
      "../components/tutorials/Shortcut"
    ) /* webpackChunkName: "Shortcut" */
);

const About = lazy(
  () => import("../pages/About") /* webpackChunkName: "About" */
);

const Privacy = lazy(
  () => import("../pages/Privacy") /* webpackChunkName: "Privacy" */
);

const Terms = lazy(
  () => import("../pages/Terms") /* webpackChunkName: "Terms" */
);

const Editor = lazy(
  () => import("../pages/Editor") /* webpackChunkName: "Editor" */
);

const NotFound = lazy(
  () => import("../pages/NotFound") /* webpackChunkName: "NotFound" */
);

const Filter = lazy(
  () => import("../pages/Filter") /* webpackChunkName: "Filter" */
);

export const useAppRoutes = () => {
  return useRoutes([
    {
      element: <Outlet />,
      children: [
        {
          path: "/",
          children: [
            {
              index: true,
              element: <Intro />,
            },
          ],
        },
        {
          path: "/editor",
          children: [
            {
              index: true,
              element: <Editor />,
            },
          ],
        },
        {
          path: "/manuals",
          children: [
            {
              index: true,
              element: <Tutotials />,
            },
            {
              path: "Screen",
              element: <Screen />,
            },
            {
              path: "NewArtify",
              element: <NewArtify />,
            },
            {
              path: "NewFromFile",
              element: <NewFromFile />,
            },
            {
              path: "CanvasControl",
              element: <CanvasControl />,
            },
            {
              path: "AddCrop",
              element: <AddCrop />,
            },
            {
              path: "AddDrawing",
              element: <AddDrawing />,
            },
            {
              path: "AddText",
              element: <AddText />,
            },
            {
              path: "AddImage",
              element: <AddImage />,
            },
            {
              path: "UpdateLayer",
              element: <UpdateLayer />,
            },
            {
              path: "Eraser",
              element: <Eraser />,
            },
            {
              path: "ImageFilter",
              element: <ImageFilter />,
            },
            {
              path: "IntervalSettings",
              element: <IntervalSettings />,
            },
            {
              path: "AddFrame",
              element: <AddFrame />,
            },
            {
              path: "RemoveFrame",
              element: <RemoveFrame />,
            },
            {
              path: "Preview",
              element: <Preview />,
            },
            {
              path: "SaveGIFToLocal",
              element: <SaveGIFToLocal />,
            },
            {
              path: "Shortcut",
              element: <Shortcut />,
            },
          ],
        },
        {
          path: "/about",
          element: <About />,
        },
        {
          path: "/privacy",
          element: <Privacy />,
        },
        {
          path: "/terms",
          element: <Terms />,
        },
        {
          path: "/filter",
          element: <Filter />,
        },
        {
          path: "*",
          element: <NotFound />,
        },
      ],
    },
  ]);
};
